@import 'reset';
body {
  font: 400 16px/200% Merriweather, georgia, times, serif;
}

/* And we're off... */

#site-header {
  width: 400px;
  background: #0059B2;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  color: #fff;
  border-right: 10px solid #eee;
  text-align: right;
  padding: 180px 30px 0 0;
  a {
    text-decoration: none;
    color: #fff;
  }
  nav {
    margin: 130px 0 0;
    text-transform: uppercase;
    font: 200 16px Raleway, Helvetica, sans-serif;
    a {
      color: #ccc;
      background: #0059B2;
      transition-property: background;
      transition-duration: .5s;
      transition-timing-function: linear;
      display: inline-block;
      margin-bottom: 20px;
      padding: 8px;
      &:hover {
        background: #337AC1;
      }
    }
  }
}

#site-name {
  font: 200 24px Raleway, Helvetica, sans-serif;
  text-transform: uppercase;
}

#main-content-wrapper {
  padding-left: 480px;
  padding-top: 146px;
}

#main-content {
  max-width: 780px;
  padding-right: 30px;
  h1 {
    color: #8C4600;
    font: 100 64px Raleway, Helvetica Neue, Helvetica, sans-serif;
  }
  h2 {
    margin: 58px 0 0;
    color: #8C4600;
    font: 400 24px Raleway, Helvetica Neue, Helvetica, sans-serif;
  }
  p {
    color: #666;
    font: 400 16px/200% Merriweather, georgia, times, serif;
    margin: 18px 0;
    &.post-meta {
      font-size: 12px;
      font-style: italic;
    }
  }
  a {
    text-decoration: none;
    color: #930;
  }
}

#comments {
  li {
    padding: 20px;
    display: flex; // background: #ddd;
    margin-bottom: 40px;
    border: 1px solid #ccc;
    &:first-child {
      margin-top: 40px;
    }
    &:nth-child(odd) {
      background: #f8f8f8;
    }
  }
  .commentmetadata {
    a {
      font-size: 12px;
      color: inherit;
    }
  }
  .meta {
    width: 20%; //background: #f8f8f8;
    border-right: 2px dotted #ccc;
    p {
      margin: 0;
      padding: 0;
    }
  }
  .comment-text {
    width: 80%;
    p {
      margin-left: 20px;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #site-header {
    position: static;
    top: inherit;
    left: inherit;
    bottom: inherit;
    border-right: 0 none;
    border-top: 3px solid #eee;
    width: auto;
    padding: 12px;
    text-align: center;
  }
  #site-header nav {
    display: none;
  }
  #main-content-wrapper {
    padding: 30px 0 0;
  }
  #main-content {
    padding: 0 24px;
  }
}